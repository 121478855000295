<template>
  <v-app class="grey lighten-4"
    ><section class="py-5 h-100 d-flex justify-content-center">
      <!-- <NavBar /> -->
      <v-card elevation="2" outlined class="w-75 m-auto">
        <v-card-text class="p-4">
          <v-row>
            <v-col cols="12" sm="12" lg="6" class="border-right">
              <h2 class="text-primary-color">{{ $t("qrate") }}</h2>
              <br />
              <img
                alt="QRate"
                class="logo_img_lg w-100 h-75"
                src="../../assets/mobile_payments.png"
                style="max-height: 75% !important"
              />
            </v-col>

            <v-col cols="12" sm="12" lg="6" class="pb-2">
              <div class="w-100 m-auto p-5">
                <h2 class="text-center text-primary-color font-weight-bold">
                  {{ $t("userRegistraion") }}
                </h2>
                <br />

                <b-alert
                  v-if="errorMessage"
                  :show="dismissCountDown"
                  dismissible
                  fade
                  variant="danger"
                  @dismiss-count-down="countDownChanged"
                >
                  {{ errorMessage }}
                </b-alert>

                <label class="text-midgray-color">{{
                  $t("forgetPassowrd.confirm_mobile_num")
                }}</label>
                <v-text-field
                  v-model="emailId"
                  :error-messages="emailErrors"
                  :label="$t('emailID')"
                  required
                  clearable
                  @input="$v.emailId.$touch()"
                  @blur="$v.emailId.$touch()"
                >
                </v-text-field>

                <v-btn
                  block
                  rounded
                  color="primary"
                  @click="onSubmitVerify"
                  class="btn-primary mt-3 text-capitalize"
                >
                  <div class="button-loader" v-if="loader">
                    <span class="spinner-border spinner-border-sm"></span>
                  </div>
                  <div v-else>{{ $t("verify") }}</div>
                </v-btn>

                <div
                  class="text-dark mt-4 d-flex justify-content-center align-items-baseline"
                >
                  <span>{{ $t("back_to") }}</span>
                  <v-btn
                    class="text-capitalize text-primary-color p-2"
                    color="primary"
                    plain
                    @click="$router.push('/login')"
                  >
                    {{ $t("loginPage") }}?
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section></v-app
  >
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import userservice from "@/services/UserService/userservice";
export default {
  name: "Login",
  data() {
    return {
      emailId: "",
      errorMessage: "",
      loader: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      countDownAlert: 0,
      onCheckFlag: false,
      country_options: [],
      showError: false,
    };
  },
  validations: {
    emailId: {
      required,
      email,
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.emailId.$dirty) return errors;
      !this.$v.emailId.required && errors.push("E-mail is required");
      !this.$v.emailId.email && errors.push("Must be valid e-mail");
      return errors;
    },
  },
  methods: {
    async onSubmitVerify() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const userDetails = {
        user_email: this.emailId,
      };
      try {
        this.loader = true;
        const responseData = await userservice.registerAccount(userDetails);
        if (responseData && responseData.status === 200) {
          this.loader = false;
          this.errorMessage = "";
        } else if (responseData.response.data.message) {
          this.errorMessage = responseData.response.data.message;
          this.dismissCountDown = this.dismissSecs;
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";

.custom-select {
  margin-top: 18px;
}
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 5px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    border: 1px solid red !important;
    color: $dimgrey;
    background: $white;
  }
}
::v-deep ul {
  list-style-type: none !important;
}
.card-title {
  color: $dimgrey;
}
.card-text {
  color: $lightslategrey;
}
label {
  color: $lightslategrey;
}
.logo_img {
  max-height: 70px;
}
.div-signup {
  color: $primary;
  cursor: pointer;
}
::v-deep .btn-light {
  border: 1px solid #d3d3d3 !important;
}
.card {
  border: none;
}
.floating-placeholder {
  color: red;
  font-size: 14px;
  text-align: -webkit-left;
}
.label-policy {
  display: flex;
}
</style>
